<template>
	<div v-if="q" :class="{ 'inline-flex items-center': !profileOrMatchOverview }">
		<div v-if="!profileOrMatchOverview" :class="{ 'cursor-pointer': !viewOnly }">
			<!--icon-->
			<HokIcon
				v-if="
					q.module &&
					['commonyesno', 'yes_no'].includes(q.module.toLowerCase()) &&
					q.moduleOptions.answer === true
				"
				name="icon:checkmark-2"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="
					q.module &&
					['commonyesno', 'yes_no'].includes(q.module.toLowerCase()) &&
					q.moduleOptions.answer === false
				"
				name="icon:close"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="q.module && q.module.toLowerCase() === 'distancehome'"
				name="icon:pin-2"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="
					q.module &&
					q.module.toLowerCase() === 'range' &&
					q.moduleOptions.unit &&
					q.moduleOptions.unit.includes('EUR')
				"
				name="icon:euro"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="(q.module && q.module.toLowerCase() === 'joblevel') || q.module === 'job'"
				name="icon:suitcase"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="
					(q.module && q.module.toLowerCase() === 'educationlevel') || q.module === 'education'
				"
				name="icon:education-new"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="q.module && q.module.toLowerCase() === 'date'"
				name="icon:calendar"
				color="main-business"
				class="inline self-center"
			/>
			<HokLink
				v-else-if="type === 'attachment' && q.moduleOptions && q.moduleOptions.file"
				:to="q.moduleOptions.file"
			>
				<HokIcon name="icon:bewerbung" color="main-business" class="inline self-center" />
			</HokLink>
			<HokIcon
				v-else-if="
					type === 'attachment' &&
					(typeof q.currentAnswer === 'undefined' || q.currentAnswer === false) &&
					!viewOnly
				"
				name="icon:alert"
				color="purple"
				class="inline self-center"
			/>
			<HokIcon
				v-else-if="type === 'attachment'"
				name="icon:bewerbung"
				color="main-business"
				class="inline self-center"
			/>
			<HokIcon v-else name="icon:chat" color="main-business" class="inline self-center" />
		</div>
		<div :class="{ 'ml-3': !profileOrMatchOverview }">
			<!--question-->
			<!--add mb-0 if it is an yes/no question or a document-->
			<!--otherwise mb-2-->
			<div
				v-if="showQuestion"
				class="leading-tight"
				:class="
					q.module &&
					['commonyesno', 'yes_no', 'cv', 'certificate', 'document'].includes(
						q.module.toLowerCase()
					) &&
					!profileOrMatchOverview
						? 'mb-0'
						: 'mb-2'
				"
			>
				<div
					v-if="type === 'attachment' && q.moduleOptions && q.moduleOptions.file"
					:class="[
						{ 'flex justify-between': profileOrMatchOverview },
						{ 'cursor-pointer': !viewOnly }
					]"
				>
					<HokLink class="text-color-text" :to="q.moduleOptions.file">
						<strong v-if="profileOrMatchOverview">{{ shortQuestion }}</strong>
						<strong v-else>{{ q.question }}</strong>
					</HokLink>
					<div class="ml-1">
						<HokIcon
							v-if="profileOrMatchOverview && !viewOnly"
							color="main"
							:size="5"
							pointer
							name="icon:pencil"
						/>
					</div>
				</div>
				<div
					v-else-if="type === 'attachment' && !q.moduleOptions"
					:class="[
						{ 'flex justify-between': profileOrMatchOverview },
						{ 'cursor-pointer': !viewOnly }
					]"
				>
					<p class="mb-0">
						<strong v-if="profileOrMatchOverview">{{ shortQuestion }}</strong>
						<strong v-else>{{ q.question }}</strong>
					</p>
					<HokButton is-text color="main" font-weight="light"> + Hinzufügen </HokButton>
				</div>
				<div v-else :class="{ 'flex justify-between space-x-3': profileOrMatchOverview }">
					<strong :class="{ 'cursor-pointer': !viewOnly }">{{ q.question }}</strong>
					<div class="ml-1">
						<HokIcon
							v-if="profileOrMatchOverview && !viewOnly"
							color="main"
							:size="5"
							pointer
							name="icon:pencil"
						/>
					</div>
				</div>
			</div>
			<!--open question answers-->
			<p
				v-if="
					q.module &&
					!['commonyesno', 'yes_no', 'cv', 'certificate', 'document'].includes(
						q.module.toLowerCase()
					) &&
					typeof q.currentAnswer === 'string'
				"
				class="leading-tight mb-0 break-words text-sm"
				:class="[{ 'cursor-pointer': !viewOnly }, { 'mb-0': profileOrMatchOverview }]"
				:data-type="q.type"
			>
				<client-only v-if="beautifyText && shortAnswer">
					<BeautifyText :text="shortAnswer" />
				</client-only>
				<template v-else>{{ shortAnswer || 'Keine Antwort' }}</template>
			</p>
			<!--yes/no boolean - answers-->
			<!--yes/no string - answers-->
			<template
				v-else-if="
					q.module &&
					['commonyesno', 'yes_no'].includes(q.module.toLowerCase()) &&
					typeof q.currentAnswer === 'string' &&
					profileOrMatchOverview
				"
			>
				<p class="text-sm" :class="{ 'mb-0': profileOrMatchOverview }">{{ q.currentAnswer }}</p>
			</template>
			<template
				v-else-if="
					q.module &&
					['commonyesno', 'yes_no'].includes(q.module.toLowerCase()) &&
					typeof q.currentAnswer === 'boolean'
				"
			>
				<HokIcon
					v-if="q.currentAnswer && !profileOrMatchOverview"
					name="icon:checkmark-2"
					color="main-business"
					class="inline self-center"
				/>
				<HokIcon
					v-else-if="!profileOrMatchOverview"
					name="icon:close"
					color="main-business"
					class="inline self-center"
				/>
			</template>
			<!--documents as answer-->
			<template
				v-else-if="
					q.module &&
					['certificate', 'document'].includes(q.module.toLowerCase()) &&
					typeof q.currentAnswer === 'string'
				"
			>
				<p class="mb-0 text-xs">Für diese Bewerbung hochgeladen</p>
			</template>
			<template
				v-else-if="
					q.module &&
					['certificate', 'document', 'cv'].includes(q.module.toLowerCase()) &&
					typeof q.currentAnswer === 'string' &&
					(!q.moduleOptions || !q.moduleOptions.file)
				"
			>
				<p class="mb-0 text-xs">Bitte per E-Mail hochladen</p>
			</template>
			<!--not answered-->
			<template v-else-if="typeof q.currentAnswer === 'undefined' || q.currentAnswer === false">
				<p
					v-if="type === 'attachment' && q.module && q.module.toLowerCase() === 'cv'"
					class="cursor-pointer mb-0 text-xs"
					data-cy="attachmentMissing"
				>
					Nicht vorhanden
				</p>
				<p
					v-else-if="type === 'attachment' && profileOrMatchOverview"
					class="mb-0 text-xs text-color-purple"
				>
					Ausstehend
				</p>
				<p v-else-if="type === 'attachment'" class="mb-0 text-xs">Nicht hochgeladen</p>
				<p v-else class="mb-0 text-xs">Nicht beantwortet</p>
			</template>
		</div>
	</div>
</template>

<script lang="ts">
import HokLink from '@hokify/shared-components-nuxt3/lib/components/HokLink.vue';
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'MatchQuestion',
	components: {
		HokLink,
		BeautifyText
	},
	computed: {
		shortAnswer(): string {
			const shortAnswer = this.q.currentAnswer;
			if (shortAnswer && shortAnswer.length > 550) {
				shortAnswer.slice(0, 550);
				return `${shortAnswer}...`;
			}
			return shortAnswer || '';
		},
		shortQuestion(): string {
			return this.q.question.replace('Dokument ', '');
		}
	},
	props: {
		q: { type: Object, required: true },
		type: { type: String, default: 'question' },
		viewOnly: { type: Boolean, default: true },
		showQuestion: { type: Boolean, default: true },
		beautifyText: { type: Boolean, default: false },
		linkToMatch: { type: String, default: '' },
		profileOrMatchOverview: { type: Boolean, default: false }
	}
});
</script>
