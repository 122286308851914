import { defineStore } from 'pinia';

export const useSurveyStore = defineStore('survey', {
	state: () => ({}),
	actions: {
		async getSurvey({
			relationId,
			relationType,
			audienceId
		}: {
			relationId: string;
			relationType: string;
			audienceId?: string | boolean;
		}) {
			try {
				return await this.$nuxt.$hokFetch(
					`/app-api/jobseeker/exitsurvey?relationId=${relationId}&relationType=${relationType}${
						audienceId ? `&audienceId=${audienceId}` : ''
					}`
				);
			} catch {
				throw new Error('could not catch exitSurvey');
			}
		},
		async answerSurvey({
			relationId,
			relationType,
			userAnswers,
			surveyId,
			utm
		}: {
			relationId: string;
			relationType: string;
			userAnswers: string[];
			surveyId: string;
			utm?: string;
		}) {
			try {
				await this.$nuxt.$hokFetch('/app-api/jobseeker/exitsurveyanswer', {
					method: 'POST',
					body: {
						relationId,
						relationType,
						userAnswers,
						surveyId,
						utm
					}
				});
			} catch {
				throw new Error('could not answer exitSurvey');
			}
		}
	}
});
