import { defineStore } from 'pinia';

export const useSmsStore = defineStore('sms', {
	state: () => ({}),
	actions: {
		async sendAppLink(data: { type: string; relation: string; relationId: string }) {
			try {
				return await this.$nuxt.$hokFetch('/app-api/utils/send-app-link', {
					method: 'POST',
					body: data
				});
			} catch {
				throw new Error('could not send-app-link');
			}
		}
	}
});
