<template>
	<div :class="{ 'inline-flex items-center': !profileOrMatchOverview }">
		<template v-if="!profileOrMatchOverview">
			<HokLink v-if="attachment.url && viewOnly" :to="attachment.url">
				<HokIcon name="icon:bewerbung" color="main-business" class="inline self-center" />
			</HokLink>
			<HokIcon v-else name="icon:bewerbung" color="main-business" class="inline self-center" />
		</template>
		<div :class="{ 'flex justify-between': profileOrMatchOverview }">
			<div :class="{ 'ml-3': !profileOrMatchOverview }">
				<HokLink
					v-if="attachment.url && viewOnly"
					class="font-bold"
					:class="isBusinessLink ? 'text-color-main-business underline' : 'text-color-text'"
					:to="attachment.url"
					target="_blank"
				>
					{{ attachment.title }}
				</HokLink>
				<p v-else class="mb-0 font-bold">
					{{ attachment.title }}
				</p>
				<p v-if="showUploadDate" class="text-xs mb-0">Hochgeladen {{ fromNow(attachment.date) }}</p>
			</div>
			<HokIcon
				v-if="profileOrMatchOverview && !viewOnly"
				color="main"
				:size="5"
				pointer
				name="icon:pencil"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import HokLink from '@hokify/shared-components-nuxt3/lib/components/HokLink.vue';
import { fromNow } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/from-now';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AttachmentQuestion',
	components: {
		HokLink
	},
	data() {
		return {
			fromNow
		};
	},
	props: {
		attachment: { type: Object, required: true },
		showUploadDate: { type: Boolean, default: true },
		viewOnly: { type: Boolean, default: true },
		isBusinessLink: { type: Boolean, default: false },
		profileOrMatchOverview: { type: Boolean, default: false }
	}
});
</script>
